$Animation_Time_Boxes_On: 0.4s;
$Animation_Time_Boxes_Off: 0.2s;


.Engineering_BackgroundFull {
    position: relative;
    height: 100%;
    width: 100%;
	// background-image: url(/images/Backgrounds/Engineering.jpg);
	// background-image: url(/images/Backgrounds/orange.jpg);
	// background-image: url(/images/Backgrounds/consulting1170901681_orange.jpg);
	
    background-repeat: no-repeat;
    background-size: cover;
	background-position: center;
	touch-action: none;
	overflow-x:hidden;
}

.Engineering_BackgroundTop{
	// background-image: url(/images/Backgrounds/orange.jpg);
	background-image: url(/images/Backgrounds/OrangeEngineeringTilesBackground.png);
	
	background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}
$MainColor:$Artibulles_Orange;
$Color1:$Artibulles_Green;
$Color2:$Artibulles_Pink;
$Color3:$Artibulles_Blue;
$Color4:$Artibulles_Yellow;


.Engineering_MainSummary_MainCircle {
    fill: $MainColor;
}


.Engineering_MainCircle_Line {
    stroke: $MainColor;
    stroke-width: 8
}

.Engineering_MainCircle_Line_Hidden {
    stroke: $MainColor;
    stroke-width: 0
}

.Engineering_MainSummary_Circle1 {
    fill: $Color1;
}

.Engineering_Circle1 {
    stroke: $Color1;
    stroke-width: 8
}

.Engineering_Circle1_Hidden {
    stroke: $Color1;
    stroke-width: 0
}

.Engineering_MainSummary_Circle2 {
    fill: $Color2;
}

.Engineering_Circle2 {
    stroke: $Color2;
    stroke-width: 8
}

.Engineering_Circle2_Hidden {
    stroke: $Color2;
    stroke-width: 0
}

.Engineering_MainSummary_Circle3 {
    fill: $Color3;
}

.Engineering_Circle3 {
    stroke: $Color3;
    stroke-width: 8
}

.Engineering_Circle3_Hidden {
    stroke: $Color3;
    stroke-width: 0
}

.Engineering_MainSummary_Circle4 {
    fill: $Color4;
}

.Engineering_Circle4 {
    stroke: $Color4;
    stroke-width: 10
}

.Engineering_Circle4_Hidden {
    stroke: $Color4;
    stroke-width: 0
}
.Engineering_GeneralEngineering_Picture{
	width: 800px;
	height: auto;
}
#EngineeringApproach_Title{
	padding-top:15px;
	padding-bottom:15px;
	max-width: 800px;
	h3{
		font-family: $font__ROBOTO;
	}
	
}
#EngineeringApproach_Text{
	h3{
		font-size: 1.5rem;
		font-family: $font__ROBOTO;
	}
}
.Box_With_Icon_Wrapper_EngineeringSummary{
	flex: 0 1 450px;
	margin: 10px;
}

.Engineering_Summary_Subsection_Body{
	align-self: flex-start;
	padding: 10px;
	h3{
		font-family: $font__ROBOTO;
		text-align: left;
		font-size: 1.5rem;
	}
	h4 {
		font-family: $font__ROBOTO;
		text-align: left;
		font-size: 1.4rem;
	}
}
.SectionWrapper_Horizontal_Standard_Wrapp {
	flex-wrap: wrap;
	padding: 10px 0px 10px 0px;
}
.Engineering_SubTitle_Summary_Box{
	align-self: flex-start;
	
}
.Engineering_SubTitle_Summary_BoxContent{
	align-self: flex-start;
	padding-top: 20px;
	// h4{
	// 	font-family: $font__ROBOTO;
	// 	text-align: left;
	// 	margin: 0px 0px 10px 10px;
	// }
	// p{
		
	// 	margin: 0px 0px 0px 10px;
	// }
}

.Engineering_SubTitle_Summary_BoxContent_Part{
	padding: 10px 10px 10px 20px;

}