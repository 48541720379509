.footer {
	background-color: $Nav_Menu_FullWidth_Color;
	color: white;
}

.FooterWrapper {
	display: flex;
	flex-direction: column;
	max-width: 1000px;
	width: 100%;
	margin: 0 auto;

}

.FooterPart_Multi_Cols {
	display: flex;
	flex-direction: row;
	justify-content: space-around;
	padding: 10px 0px 10px 0px;

}

.FooterPart_Single_Cols {
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 10px;

	p {
		padding: 0;
		margin: 0;
	}

}

.FooterPart_Single_Cols_Mobile {
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 10px;

	ul {
		margin: 2px 0px 2px 0px
	}

	h3 {
		margin: 5px 0px 5px 0px
	}

	p {
		padding: 0;
		margin: 0;
	}
}
.Legal{
	flex-direction : row;
	flex-wrap :wrap;
	font-size :0.9em
}

.FooterColLeft {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	flex: 1 1 auto;
	margin: 10px;

	h3 {
		margin: 0;
		text-align: left;
	}

	ul {
		list-style: none;
		padding: 5px 0px 5px 0px;
		margin: 10px 0px 0px 0px;
	}


}

.FooterColLeft_Company {
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	flex: 1 1 auto;
	margin: 10px;
	padding-right: 20px;

	p {
		padding: 0;
		margin: 0;
	}
}

.FooterColRight_Company {
	display: flex;
	padding-left: 20px;
	flex-direction: column;
	align-items: flex-start;
	justify-content: center;
	flex: 1 1 auto;
	margin: 10px;
}

.Footer_ContactInfoWrapper {
	display: flex;
	flex-direction: row;
	align-content: center;
	align-items: center;
	padding: 5px 5px 5px 5px;

	h4 {
		margin: 0;
		line-height: 20px;
	}

	i {
		font-size: 16px;

		padding: 0px 12px 0px 10px;
		margin: 4px 0px 0px 0px;
	}
}

.Footer_WebInfoWrapper {
	display: flex;
	flex-direction: row;
	align-content: center;
	align-items: center;
	padding: 5px 5px 5px 5px;
	text-decoration: none;
	color: white;

	p {
		margin: 0;
		line-height: 20px;
	}

	i {
		font-size: 24px;

		padding: 0px 8px 0px 10px;
		margin: 0px 0px 0px 0px;
	}
}

.Footer_WebInfoWrapper:hover {
	border-bottom: 1px solid currentColor;
	color: white;
}


.FooterColMiddle {
	flex: 1 1 auto;
	flex-direction: column;
	margin: 10px;
	align-items: center;

	h3 {
		margin: 0;
		text-align: left;
	}

	ul {
		list-style: none;
		padding: 5px 0px 5px 0px;
		margin: 10px 0px 0px 0px;
	}

}

.FooterColRight {
	flex: 0 1 auto;
	flex-direction: column;
	margin: 10px;
	align-items: flex-end;

	h3 {
		margin: 0;
		text-align: left;
	}

	ul {
		list-style: none;
		padding: 5px 0px 5px 0px;
		margin: 10px 0px 0px 0px;
	}

}

.Footer_Section_Separator {
	border-bottom: 1px solid white;
	width: 100%;
	height: 5px;

}

.FooterNavLink {
	text-decoration: none;
	text-transform: none;
	color: white;
	line-height: 30px;
	margin: 2px;
}

.FooterNavLink:Hover {
	text-decoration: none;
	text-transform: none;
	color: white;
	text-decoration: none;
	border-bottom: 1px solid currentColor;
	margin: 2px;
}

.FooterNavLink_Active {
	text-decoration: none;
	text-transform: none;
	color: white;

}

.FooterCompanyWithLogo {
	display: flex;
	flex-direction: row;
	align-content: center;
	align-items: center;

	h3 {
		margin: 0;
		padding-left: 10px;
	}

	img {
		width: 30px;
		height: auto;

	}
}

#WebDesign {
	p {
		font-size: 18px;
		margin: 0;
		padding: 0;
	}
}

.Footer_SocialIconsWrapper {
	display: flex;
	flex-direction: column;
	justify-content: center;

	li {
		display: flex;
		flex-direction: row;
		align-items: center;
		padding: 10px 0px 10px 0px;
	}

	p {
		padding-left: 15px;
		margin: 0;
	}

}

.IconBoxSocial {
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: rgb(255, 255, 255);
	border-bottom-color: rgb(51, 204, 255);
	border-bottom-left-radius: 100%;
	border-bottom-right-radius: 100%;
	border-bottom-style: none;
	border-bottom-width: 0px;
	border-image-outset: 0;
	border-image-repeat: stretch stretch;
	border-image-slice: 100%;
	border-image-source: none;
	border-image-width: 1;
	border-left-color: rgb(168, 168, 168);
	border-left-style: none;
	border-left-width: 0px;
	border-right-color: rgb(168, 168, 168);
	border-right-style: none;
	border-right-width: 0px;
	border-top-color: rgb(168, 168, 168);
	border-top-left-radius: 100%;
	border-top-right-radius: 100%;
	border-top-style: none;
	border-top-width: 0px;
	box-shadow: rgba(0, 0, 0, 0.56) 0px 7px 20px -12px, rgba(0, 0, 0, 0.118) 0px 4px 25px 0px, rgba(0, 0, 0, 0.2) 0px 8px 10px -5px;
	box-sizing: border-box;
	color: rgb(51, 204, 255);
	font-family: $font__PTSANS;
	font-feature-settings: normal;
	font-kerning: auto;
	font-language-override: normal;
	font-size: 18px;
	font-size-adjust: none;
	font-stretch: normal;
	font-style: normal;
	font-variant: normal;
	font-variant-alternates: normal;
	font-variant-caps: normal;
	font-variant-east-asian: normal;
	font-variant-ligatures: normal;
	font-variant-numeric: normal;
	font-variant-position: normal;
	font-weight: 300;
	line-height: 23.4px;

	min-height: 0px;
	overflow-wrap: break-word;

	text-align: center;
	transition-delay: 0s;
	transition-duration: 0.2s;
	transition-property: all;
	transition-timing-function: linear;
	vertical-align: baseline;
	height: 50px;
	width: 50px;

	padding: 2px;




	a,
	a:visited,
	a:focus {
		color: rgb(51, 204, 255);

		i {
			font-size: 30px;
		}

	}
}



.IconBoxSocial:hover {
	background-color: #E72486;
	transform: scale(1.2);
	transition: all 0.2s linear 0s;

}

.animatedOnHoverIconSocial {
	display: inline-block;
	font-size: 35px;
	display: inline-block;
	padding: 0;
	margin: 0;
}