$Animation_Time_Boxes_On: 0.4s;
$Animation_Time_Boxes_Off: 0.2s;
$Artib_HomePage_SVGElementScaling: 1.3;
$Artib_Small_Screen_BoxSummary_Height: 200px;
$Artib_Large_Screen_BoxSummary_Height:250px;
$Artib_Recangle_MaxWidth:500px;

a {
	text-decoration: none;
}
a:hover {
    text-decoration: none;
    color: black;
}


.HomePage_Top_DivWrapper {
    position: relative;
    height: 200px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    // background-color: black;
    color: white;
}

.HomePage_Top_DivWrapper_Mobile {
    position: relative;
    height: 100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: black;
    color: white;
}


.HomePage_Summary_Title{
	font-size: 1.2rem;
}
.HomePage_Summary_Title_UL{
	li{
	margin-top: 4px;
	}
}

.HomePageCompanyH1_Mobile {
    margin: 0;
    text-align: center;
    font-size: 25px;
}

.HomePageCompanyH2_Mobile {
    margin: 0;
    text-align: center;
    font-size: 18px;
}

.HomePage_VisionButton {
    text-align: center;
    width: 150px;
    margin-top: 10px;
    background-color: $Artibulles_Orange;
    border-bottom-color: white;
    border-bottom-left-radius: 2px;
    border-bottom-right-radius: 2px;
    border-bottom-style: solid;
    border-bottom-width: 0px;
    border-image-outset: 0;
    border-image-repeat: stretch stretch;
    border-image-slice: 100%;
    border-image-source: none;
    border-image-width: 1;
    border-left-color: white;
    border-left-style: solid;
    border-left-width: 0px;
    border-right-color: white;
    border-right-style: solid;
    border-right-width: 0px;
    border-top-color: white;
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;
    border-top-style: solid;
    border-top-width: 0px;
    box-shadow: rgba(0, 0, 0, 0.14) 0px 1px 2px 0px, rgba(0, 0, 0, 0.2) 0px 3px 1px -2px, rgba(0, 0, 0, 0.12) 0px 1px 5px 0px;
    box-sizing: border-box;
    color: white;
    cursor: default;
    display: inline-block;
    font-family: "Source Sans Pro", sans-serif;
    font-feature-settings: normal;
    font-kerning: auto;
    font-language-override: normal;
    font-size: 14px;
    font-size-adjust: none;
    font-stretch: normal;
    font-style: normal;
    font-variant: normal;
    font-variant-alternates: normal;
    font-variant-caps: normal;
    font-variant-east-asian: normal;
    font-variant-ligatures: normal;
    font-variant-numeric: normal;
    font-variant-position: normal;
    font-weight: 600;
    letter-spacing: 1px;
    line-height: 15.45px;
    overflow-wrap: break-word;
    padding-bottom: 7px;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 7px;
    text-align: center;
    text-decoration: none;
    text-decoration-color: white;
    text-decoration-line: none;
    text-decoration-style: solid;
    text-transform: uppercase;
    // transition-delay: 0s;
    // transition-duration: 0.2s;
    // transition-property: all;
    // transition-timing-function: linear;
    vertical-align: baseline;
    z-index: 200;
    animation: AnimatedVision 3s ease-in-out infinite;
}

.HomePage_LearnMore:hover {
    -webkit-box-shadow: 0px 0px 11px 6px rgba(150, 145, 150, 1);
    -moz-box-shadow: 0px 0px 11px 6px rgba(150, 145, 150, 1);
    box-shadow: 0px 0px 11px 6px rgba(150, 145, 150, 1);
    color: white;
}

.HomePage_BackgroundFull_Animated_Circle_Wrapper {
    position: absolute;
    z-index: 100;
    margin: 0 auto;
    left: 50%;
    transform: translate(-50%, 200px);
}

.HomePage_BackgroundFull_Animated_Circle {
    animation-duration: 120s;
    animation: AnimatedCircle 1s linear infinite;
    will-change: transform;
}

@keyframes AnimatedVision {
    0% {
        box-shadow: rgba(0, 0, 0, 0.14) 0px 1px 2px 0px, rgba(0, 0, 0, 0.2) 0px 3px 1px -2px, rgba(0, 0, 0, 0.12) 0px 1px 5px 0px;
    }
    50% {
        box-shadow: 0px 0px 11px 6px rgba(150, 145, 150, 1);
    }
    100% {
        box-shadow: rgba(0, 0, 0, 0.14) 0px 1px 2px 0px, rgba(0, 0, 0, 0.2) 0px 3px 1px -2px, rgba(0, 0, 0, 0.12) 0px 1px 5px 0px;
    }
}

.HomePage_LearnMore_Active {}



.AnimatedLogo_Mobile {
    display: block;
    height: 90px;
    margin: 0 auto;
    z-index: 1;
    opacity: 0.4;
}



.Header_Separtor_Mobile {
    display: block;
    position: absolute;
    width: 100%;
    height: 100px;
    z-index: 10;
    margin: 0;
    padding: 0;
    top: 0px;
}

.SVGseparator_Mobile {
    position: relative;
    height: 100px;
    width: 100%;
    display: block;
    padding: auto;
    margin: auto;
}


.Separator_Anime_Left {
    stroke-dasharray: 1000;
    stroke-dashoffset: 1000;
    stroke-opacity: 0.85;
    fill: white;
    stroke: white;
    stroke-width: 5;
    animation: AnimeVisionLeft 3s linear 1s infinite;
}

.Separator_Anime_Left2 {
    stroke-dasharray: 950;
    stroke-dashoffset: 50;
    animation: AnimeVisionRight 3s linear 1s infinite;
}

@keyframes AnimeVisionLeft {
    from {
        stroke-dashoffset: 1000;
    }
    to {
        stroke-dashoffset: 0;
    }
}

.Separator_Anime_Right {
    stroke-dasharray: 1000;
    stroke-dashoffset: 900;
    stroke-opacity: 0.85;
    fill: white;
    stroke: white;
    stroke-width: 5;
    animation: AnimeVisionRight 3s linear 1s infinite;
}

.Separator_Anime_Right2 {
    stroke-dasharray: 950;
    stroke-dashoffset: 50;
    animation: AnimeVisionRight 3s linear 1s infinite;
}

@keyframes AnimeVisionRight {
    from {
        stroke-dashoffset: 1000;
    }
    to {
        stroke-dashoffset: 0;
    }
}


.Animated_Circle_Background{
	stroke: black;
	stroke-width: 8;
	fill: none;
	stroke-dasharray: 950;
    stroke-dashoffset: 50;
}

.Animated_Circle_Anime{
	fill: none;
	stroke: white;
	stroke-width: 8;
	stroke-dasharray: 1000;
    stroke-dashoffset: 900;

}


.HomePage_Section_Main_Wrapper {
    display: flex;
    height: 1000px;
    justify-content: center;
    align-items: center;
    padding: 0px 20px 0px 20px;
	transition: all 0.4s;
	font-size: 1rem;
	width: 100%;
	max-width: 1600px;
	margin: 0 auto;
}

.HomePage_Section_Main_Wrapper_Hidden {
    display: none;
    justify-content: center;
    align-items: center;
    padding: 0px 20px 0px 20px;
    opacity: 0;
    transition: all 0.4s;
}

.HomePage_Section_Main_Wrapper_Disappear {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px 20px 0px 20px;
    opacity: 0;
    transition: all 0.4s;
}

.HomePage_Section_Main_Wrapper_Appear {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px 20px 0px 20px;
    opacity: 0;
    transition: all 0.4s;
}

.HomePage_Section_Main_Wrapper_Vision {
    display: flex;
    position: relative;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding: 0px 20px 0px 20px;
    
    opacity: 1;
    transition: all 0.4s;
    color: white;
}

.HomePage_Section_Main_Wrapper_Vision_Appear {
    display: flex;
    position: relative;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0px 20px 0px 20px;
    
    opacity: 0;
    transition: all 0.4s;
}

.HomePage_Section_Main_Wrapper_Vision_Disappear {
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
    padding: 0px 20px 0px 20px;
    
    opacity: 0;
    transition: all 0.4s;
}

.HomePage_Section_Main_Wrapper_Vision_Hidden {
    display: none;
    justify-content: center;
    align-items: center;
    padding: 0px 20px 0px 20px;
    
    opacity: 1;
    transition: all 0.4s;
}

.HomePage_Vision_Title_Wrapper {
    max-width: 1500px;
    background-color: rgba(0, 0, 0, 0.851);
    margin-top: 10px;
    padding: 10px;
    font-size: 18px;
    color: rgb(252, 252, 252);
    border-radius: 8px;
    p {
        margin-top: 5px;
    }
}

.HomePage_Vision_Intro_Wrapper {
    max-width: 1500px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.851);
    margin-top: 10px;
    padding: 10px 10px 20px 10px;
    font-size: 18px;
    color: rgb(252, 252, 252);
    border-radius: 8px;
    h1 {
        text-align: center;
        max-width: 1000px;
        margin: 0 auto;
        margin-bottom: 35px;
        padding-top: 15px;
    }
}

.HomePage_Vision_Intro_Block {
    display: flex;
    flex-direction: row;
    justify-content: center;
    max-width: 1450px;
}
.HomePage_Vision_Intro_Block_Text_Title {
	margin : 0px 0px 10px 0px
}
.HomePage_Vision_Intro_Block_Icon {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
	justify-content: flex-start;
	
	img{
		max-width: 600px;
		height: auto;
	}
}

.HomePage_Vision_Intro_Block_Text {
    flex: 1 1 auto;
    padding-left: 30px;
    text-align: justify;
}

.HomePage_Vision_Categories_Wrapper {
    max-width: 1500px;
    display: flex;
    border-radius: 8px;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 0px 0px 20px 0px;
    background-color: rgba(0, 0, 0, 0.851);
    margin-top: 40px;
}

.HomePage_Vision_Categories_block {
    max-width: 1100px;
    flex: 1 1 auto;
    display: flex;
    flex-direction: row;
    font-size: 1.1rem;
}

.HomePage_Vision_Categories_Text {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-wrap: nowrap;
    padding-right: 30px;
    text-align: justify;
	padding: 0px 20px 0px 20px;
	p{
		line-height: 30px;
	}
	
}

.HomePage_Vision_Categories_Wrapper_Title {
    width: 100%;
    height: 40px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: rgba(255, 255, 255, 0.192);
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    margin-bottom: 20px;
}

.HomePage_Vision_Categories_Picture {
    flex: 1 1 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 10px 10px 10px;
}

.HomePage_Vision_Categories_Svg {
	max-height: 450px;
	width: auto;
	
}
.HomePage_Vision_Categories_Columns_Message{
	text-align: center;
    max-width: 1100px;
	padding: 35px 10px 35px 10px;
	font-size: 1.3rem;
}
.HomePage_Vision_Categories_Columns_Title {
    text-align: center;
    max-width: 1200px;
	padding: 0px 10px 0px 10px;
	margin: 5px 0px 15px 0px;
}




.OrangeBackground{
	background-color: $Artibulles_Orange;
}
.GreenBackground{
	background-color: $Artibulles_Green;
}
.PinkBackground{
	background-color: $Artibulles_Pink;
}
.BlueBackground{
	background-color: $Artibulles_Blue;
}
.YellowBackground{
	background-color: $Artibulles_Yellow;
}
.PrincipleBackground{
	background-color: rgba(103, 102, 102, 0.598);
}



.OrangeBorder {
    // border: 4px solid $Artibulles_Orange;
}

.GreenBorder {
    // border: 4px solid $Artibulles_Green;
}

.PinkBorder {
    // border: 4px solid $Artibulles_Pink;
}

.BlueBorder {
    // border: 4px solid $Artibulles_Blue;
}

.YellowBorder {
    // border: 4px solid $Artibulles_Yellow;
}


.HomePageVisionCategories_IncreasedLeftPadding{
	padding: 0px 0px 0px 30px;
}
.HomePage_Vision_Categories_Column_Header_Orange {
    background-color: $Artibulles_Orange;
    margin: 0;
    padding: 0px 15px 0px 15px;
}

.HomePage_Vision_Categories_Column_Header_Green {
    background-color: $Artibulles_Green;
    margin: 0;
    padding: 0px 15px 0px 15px;
}

.HomePage_Vision_Categories_Column_Header_Pink {
    background-color: $Artibulles_Pink;
    margin: 0;
    padding: 0px 15px 0px 15px;
}

.HomePage_Vision_Categories_Column_Header_Blue {
    background-color: $Artibulles_Blue;
    margin: 0;
    padding: 0px 15px 0px 15px;
}

.HomePage_Vision_Categories_Column_Header_Yellow {
    background-color: $Artibulles_Yellow;
    margin: 0;
    padding: 0px 15px 0px 15px;
}


.HomePage_Section_Main_Wrapper_Mobile {
    display: flex;
    flex-direction: column;
	align-items: center;
	
	justify-content: center;
    margin: 0 auto;
    padding: 0px 10px 0px 10px;
    margin-top: 10px;
}

.HomePage_SvgWrapper_Mobile {
	display: flex;
	flex: 0 1 auto;
	justify-content: center;
	align-items: center
	
}





.HomePage_TLCircle {
    fill: $Artibulles_Orange;
}

.HomePage_TLLine {
    stroke: $Artibulles_Orange;
    stroke-width: 8;
}


.HomePage_TRCircle {
    fill: $Artibulles_Green;
}

.HomePage_TRLine {
    stroke: $Artibulles_Green;
    stroke-width: 8;
}


.HomePage_BRCircle {
    fill: $Artibulles_Blue;
    transition: all 0.5s;
}

.HomePage_BRLine {
    stroke: $Artibulles_Blue;
    stroke-width: 8;
}


.HomePage_BLCircle {
    fill: $Artibulles_Pink;
}

.HomePage_BLLine {
    stroke: $Artibulles_Pink;
    stroke-width: 8;
}

.HomePage_Rectangle_Orange_Mobile {
    width: 100%;
    min-height: 200px;
    border-radius: 15px;
    border: solid 6px $Artibulles_Orange;
    padding: 10px;
    transition: all 0.5s;
}

.HomePage_Rectangle_Pink_Mobile {
    width: 100%;
    min-height: 200px;
    border-radius: 15px;
    border: solid 6px $Artibulles_Pink;
    padding: 10px;
}

.HomePage_Rectangle_Green_Mobile {
    width: 100%;
    min-height: 200px;
    border-radius: 15px;
    border: solid 6px $Artibulles_Green;
    padding: 10px;
}

.HomePage_Rectangle_Blue_Mobile {
    width: 100%;
    min-height: 200px;
    border-radius: 15px;
    border: solid 6px $Artibulles_Blue;
    padding: 10px;
}


@media only screen and (max-width: $MediaQueryLaptop){

	.HomePage_Rectangle_Left {
		flex: 0 1 430px;
		height: 100%;
		display: flex;
		flex-direction: column;
	}
	.HomePage_Rectangle_Right {
		flex: 0 1 430px;
		height: 100%;
		display: flex;
		flex-direction: column;
	}
	

	.HomePage_Section_Main_Wrapper {
		display: flex;
		height: 1000px;
		justify-content: center;
		align-items: center;
		padding: 0px 20px 0px 20px;
		transition: all 0.4s;
		font-size: 0.9rem;
		line-height: 1.1rem;
		width: 100%;
		max-width: 1245px;
		margin: 0 auto;
	}




	}
.HomePageVisionLogoInsideText{
	height: 35px;
	width: auto;
	display: inline-block;
	padding: 0px 10px -10px 0px;
	box-sizing:content-box;
	
}

.HomePageVisionCategories_EngineeringColor{
	color: $Artibulles_Orange
}

.HomePageVisionCategories_InnovationColor{
	color: $Artibulles_Green
}
.HomePage_Summary {
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    color: white;
    border-radius: 15px;
    background-color: rgba(255, 255, 255, 0.6);
}

.HomeEngineering_BackgroundTop{
	padding-top: 10px;
    padding-bottom: 10px;
	// background-image: url(/images/Backgrounds/orange.jpg);
	background-image: url(/images/Backgrounds/OrangeEngineeringTilesBackground.png);
	
	background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}
.HomeIT_BackgroundTop {
    padding-top: 10px;
    padding-bottom: 10px;
    background-image: url(/images/Backgrounds/BlueEngineeringTilesBackground.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}
.HomeSummary_DivWrapper {
    display: flex;
    margin: 0 auto;
    flex-direction: row;
    justify-content: center;
    position: relative;
    padding: 0px 10px 0px 10px;
}

