/*fonts*/
@import url('https://fonts.googleapis.com/css?family=PT+Sans:400,700|Roboto:300,400,500,700|Source+Sans+Pro:300,400,600,700');
$font__PTSANS: 'PT Sans', sans-serif, Georgia, serif, "Times New Roman", Times;
$font__ROBOTO: 'Roboto', sans-serif, Georgia, serif, "Times New Roman", Times;
$Source_Sans_Pro: 'Source Sans Pro', sans-serif, Georgia, serif, "Times New Roman", Times;



h1,h2,h2,h4,h5,h6 {
	font-family:$font__ROBOTO;
}
body{
  font-family: $font__ROBOTO;
  
}
