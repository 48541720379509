.PageSummaryTop_Block_Combination {
    display: flex;
    flex-direction: column;
    position: absolute;
    width: $Artibulles_SummaryTopPage_BlockCombination_Width;
    height: $Artibulles_SummaryTopPage_BlockCombination_Height;
    background-color: transparent;
    margin: 0 auto;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    padding: 0;
    justify-content: center;
    align-items: center;
}

.PageSummaryTop_Block_Combination_Hidden {
    display: none;
    position: absolute;
	width: $Artibulles_SummaryTopPage_BlockCombination_Width;
    height: $Artibulles_SummaryTopPage_BlockCombination_Height;
    background-color: black;
    border-radius: 10px;
    color: white;
    margin: 0 auto;
    left: 50%;
    top: 50%;
    text-align: center;
    transform: translate(-40%, -50%);
    padding: 5px 5px 5px 5px;
}

.PageSummaryTop_Block_Combination_Wrapper_Main {
    display: flex;
    flex-direction: column;
    background-color: black;
	border-radius: 10px;
	// border: 5px solid green;
    color: white;
    width: $Artibulles_SummaryTopPage_BlockCombination_WrapperMain_Width;
    height: $Artibulles_SummaryTopPage_BlockCombination_WrapperMain_Height;
    margin-top: $Artibulles_SummaryTopPage_BlockCombination_WrapperMain_TopMargin;
    margin-left: $Artibulles_SummaryTopPage_BlockCombination_WrapperMain_LeftMargin;
}

.PageSummaryTop_Block_Combination_Title_Wrapper {
    display: flex;
    flex: 0 0 40px;
	align-items: center;
	padding: 10px 0px 10px 0px;
	border-bottom: solid 1px grey;
}

.PageSummaryTop_Block_Combination_Title_CloseModal {
    flex: 0 1 auto;
    border: none;
    background-color: transparent;
	color: white;
	align-self: flex-start;

   
}

.PageSummaryTop_Block_Combination_Title_Text {
	flex: 1 0 auto;
	padding: 0px 0px 0px 10px;
	
}

.PageSummaryTop_Block_Combination_Title_Text_First{
	font-size: 1rem;
	padding: 0;
	margin: 0;
}
.PageSummaryTop_Block_Combination_Title_Text_Second {
	font-size: 1rem;
	padding: 0;
	margin: 0;
	text-align: right;
}
.PageSummaryTop_Block_Combination_Main_Wrapper {
	display: flex;
	flex-direction: column;
    flex: 1 0 auto;
	padding: 10px 15px 10px 15px;
	font-size: 0.9rem;
	p {
		margin-bottom:5px;
	}
	ul{
		margin: 0;
	}
	
}

.PageSummaryTop_Block_Combination_Bottom_Wrapper {
    display: flex;
    flex: 0 0 50px;
    justify-content: center;
    align-items: center;
}

.PageSummaryTop_Block_Combination_Bottom_Button {
    width: 400px;
    border-bottom-color: white;
    border-bottom-left-radius: 2px;
    border-bottom-right-radius: 2px;
    border-bottom-style: solid;
    border-bottom-width: 0px;
    border-image-outset: 0;
    border-image-repeat: stretch stretch;
    border-image-slice: 100%;
    border-image-source: none;
    border-image-width: 1;
    border-left-color: white;
    border-left-style: solid;
    border-left-width: 0px;
    border-right-color: white;
    border-right-style: solid;
    border-right-width: 0px;
    border-top-color: white;
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;
    border-top-style: solid;
    border-top-width: 0px;
    box-shadow: rgba(0, 0, 0, 0.14) 0px 1px 2px 0px, rgba(0, 0, 0, 0.2) 0px 3px 1px -2px, rgba(0, 0, 0, 0.12) 0px 1px 5px 0px;
    box-sizing: border-box;
    color: white;
    cursor: default;
    display: inline-block;
    font-family: "Source Sans Pro", sans-serif;
    font-feature-settings: normal;
    font-kerning: auto;
    font-language-override: normal;
    font-size: 14px;
    font-size-adjust: none;
    font-stretch: normal;
    font-style: normal;
    font-variant: normal;
    font-variant-alternates: normal;
    font-variant-caps: normal;
    font-variant-east-asian: normal;
    font-variant-ligatures: normal;
    font-variant-numeric: normal;
    font-variant-position: normal;
    font-weight: 600;
    letter-spacing: 1px;
    line-height: 15.45px;
    overflow-wrap: break-word;
    padding-bottom: 7px;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 7px;
    text-align: center;
    text-decoration: none;
    text-decoration-color: white;
    text-decoration-line: none;
    text-decoration-style: solid;
    text-transform: uppercase;
    transition-delay: 0s;
    transition-duration: 0.2s;
    transition-property: all;
    transition-timing-function: linear;
    vertical-align: baseline;
}

.PageSummaryTop_Block_Combination_Bottom_Button:hover {
    -webkit-box-shadow: 0px 0px 11px 6px rgba(150, 145, 150, 1);
    -moz-box-shadow: 0px 0px 11px 6px rgba(150, 145, 150, 1);
    box-shadow: 0px 0px 11px 6px rgba(150, 145, 150, 1);
    color: white;
}

.PageSummaryTop_Button_Colored_Orange {
    background-color: $Artibulles_Orange;
}

.PageSummaryTop_Button_Colored_Blue {
    background-color: $Artibulles_Blue;
}

.PageSummaryTop_Button_Colored_Pink {
    background-color: $Artibulles_Pink;
}

.PageSummaryTop_Button_Colored_Yellow {
    background-color: $Artibulles_Yellow;
}

.PageSummaryTop_Button_Colored_Green {
    background-color: $Artibulles_Green;
}

.Summary_SectionTop {
    padding-top: 15px;
    padding-bottom: 15px;
}

.Summary_Top_DivWrapper {
    display: flex;
    margin: 0 auto;
    flex-direction: row;
    justify-content: center;
    
    position: relative;
    padding: 0px 20px 0px 20px;
}

.MainPage_Summary {
    display: flex;
    max-width: $Artibulles_SummaryTopPage_Max_Width;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    color: white;
    border-radius: 15px;
    background-color: rgba(255, 255, 255, 0.6);
}

.MainPage_Summary_Title {
	text-align: left;
	display: flex;
    background-color: rgba(255, 255, 255, 0.45);
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    color: black;
	padding: 10px 0px 10px 15px;
	h1{
		margin: 0px 0px 0px 10px;
	}
	align-items: center;
	
}
.MainPage_Summary_Title_SimpleIcon{
	height: 60px;
	width: auto;
}
.MainPage__Summary_Body {
    display: flex;
    position: relative;
    margin: 0;
    padding: 0;
    height: $Artibulles_SummaryTopPageBody_Height;
    flex-direction: row;
    justify-content: space-around;
}

.MainPage_Summary_SVG_Wrapper {
    display: flex;
	position: absolute;
	
}

.MainPage_Summary_SVG_4Icons{
	width:$Artibulles_SummaryTopPageSVG_Width;
	height: $Artibulles_SummaryTopPageBody_Height;
}

.MainPage_Summary_Middle {
    display: flex;
    flex: $Artibulles_SummaryTopPage_MainText_flex;
    flex-direction: column;
    color: black;
    justify-content: center;
    align-items: center;
    padding: $Artibulles_SummaryTopPage_MainText_Padding;
    margin: 0 auto;
}

.MainPage_Summary_Middle_UL_Wrapper {
	padding: 0px 0px 0px 40px;
	min-width:340px;
	
}
.MainPage_Summary_Middle_UL{
	list-style: none;
	li{
		display: flex;
		align-content: flex-start;
		align-items: flex-start;
		// line-height: 18px;
		padding: 5px 0px 5px 0px;
	}
	p{
		margin: 0;
		line-height: 25px;
	}
}

.Bullet_Logo{
	height: 20px;
	width: auto;
	// padding: 3px 0px 0px 0px;
	margin: 3px 10px 0px 0px;
}

.TopPage_MainSummary_MainCircle_InnerCircle{
	stroke: rgba(47, 47, 47, 0.98);
    stroke-width: 7;
}








#MainIcon1, #MainIcon2, #MainIcon3, #MainIcon4 {
	fill: rgba(47, 47, 47, 0.98);

}




#RightCircle1Shadow{
	opacity: 0;
	animation: AnimateCircleShadow1 6s ease-in-out infinite;
}

#RightCircle2Shadow{
	opacity: 0;
	animation: AnimateCircleShadow2 6s ease-in-out infinite;
}

#RightCircle3Shadow{
	opacity: 0;
	animation: AnimateCircleShadow3 6s ease-in-out infinite;
}

#RightCircle4Shadow{
	opacity: 0;
	animation: AnimateCircleShadow4 6s ease-in-out infinite;
}

@keyframes AnimateCircleShadow1 {
    0% {
        opacity: 0;
	}
	12.5% {
        opacity: 1;
	}
    25% {
        opacity: 0;
	}
	37.5% {
        opacity: 0;
	}
	50% {
        opacity: 0;
	}
	62.5% {
        opacity: 0;
	}
    75% {
        opacity: 0;
	}
	87.5% {
        opacity: 0;
	}
	100% {
        opacity: 0;
    }
}
@keyframes AnimateCircleShadow2 {
    0% {
        opacity: 0;
	}
	12.5% {
        opacity: 0;
	}
    25% {
        opacity: 0;
	}
	37.5% {
        opacity: 1;
	}
	50% {
        opacity: 0;
	}
	62.5% {
        opacity: 0;
	}
    75% {
        opacity: 0;
	}
	87.5% {
        opacity: 0;
	}
	100% {
        opacity: 0;
    }
}
@keyframes AnimateCircleShadow3 {
    0% {
        opacity: 0;
	}
	12.5% {
        opacity: 0;
	}
    25% {
        opacity: 0;
	}
	37.5% {
        opacity: 0;
	}
	50% {
        opacity: 0;
	}
	62.5% {
        opacity: 1;
	}
    75% {
        opacity: 0;
	}
	87.5% {
        opacity: 0;
	}
	100% {
        opacity: 0;
    }
}
@keyframes AnimateCircleShadow4 {
    0% {
        opacity: 0;
	}
	12.5% {
        opacity: 0;
	}
    25% {
        opacity: 0;
	}
	37.5% {
        opacity: 0;
	}
	50% {
        opacity: 0;
	}
	62.5% {
        opacity: 0;
	}
    75% {
        opacity: 0;
	}
	87.5% {
        opacity: 1;
	}
	100% {
        opacity: 0;
    }
}