@import"Typography";
@import"variables";
@import"_CommonPageSummaryTop";
@import"HomePage";
@import"Engineering";
@import"_footer";
@import"_PageBackground";
@import"MapboxMarker";
html,
body {
	width: 100%;
	height: 100%;
	margin: 0;
	padding: 0;
	-moz-osx-font-smoothing: grayscale;
	font-family: $font__ROBOTO;
	font-size: 16px;
  overflow: hidden;
  
}


@media only screen and (min-width: $MediaQueryLaptop) {

	html,
	body {
		width: 100%;
		height: 100%;
		margin: 0;
		padding: 0;
		-moz-osx-font-smoothing: grayscale;
		font-family: $font__ROBOTO;
		font-size: 18px;
    overflow: hidden;
    color : "black"
	}
}

#root {
	position: relative;
	height: 100%;
	width: 100%;
	// max-width: 2560px;
	margin: 0 auto;
	padding: 0;
	background-image: url(/images/Backgrounds/32.jpg);
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
}

.PageTransitionInitial {
	opacity: 0;
}

.PageTransitionFinal {
	opacity: 1;
	transition: opacity 0.2s ease-in-out;
}

.fade-enter {
	position: absolute;
	opacity: 0;
	// z-index: 1;
	color: rgb(47, 50, 60);
}

.fade-enter.fade-enter-active {
	position: absolute;
	opacity: 1;
	transition: opacity 800ms ease-in;
}
