.SummaryBackground_ES {
	padding: 45px 0px 45px 0px;
	background-image: url(/images/backgrounds/Artibulles_ES_wave.png);
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
}
.SummaryBackground_ISO {
	padding: 45px 0px 45px 0px;
	background-image: url(/images/backgrounds/Artibulles_ISO_wave.png);
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
}
.SummaryBackground_DC {
	padding: 45px 0px 45px 0px;
	background-image: url(/images/backgrounds/Artibulles_DC_wave.png);
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
}
.SummaryBackground_CIS {
	padding: 45px 0px 45px 0px;
	background-image: url(/images/backgrounds/Artibulles_CIS_wave.png);
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
}
.SummaryBackground_PM {
	padding: 45px 0px 45px 0px;
	background-image: url(/images/backgrounds/Artibulles_PM_wave.png);
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
}
.SummaryBackground_ES_Mobile {
	padding: 8px 0px 8px 0px;
	
}
.SummaryBackground_ISO_Mobile {
	padding: 8px 0px 8px 0px;
	
}
.SummaryBackground_DC_Mobile {
	padding: 8px 0px 8px 0px;
	
}
.SummaryBackground_CIS_Mobile {
	padding: 8px 0px 8px 0px;
	
}
.SummaryBackground_PM_Mobile {
	padding: 8px 0px 8px 0px;
	
}