//Site Pages Layout
$Page_Background-color: rgba(255, 255, 255, 1);
$Max_width_page: 2560px;
$Max_width_text: 1500px;
$MediaQueryPhone: 400px;
$MediaQueryLaptop: 1500px;




//TopNavBar
$Header_nav_height_Big: 80px; //Best is 50px
$Header_nav_height_Small: 60px; //Best is 50px
//SideNavBar
$SideBarNav_Top:0px;
$SideBarNav_Width: 270px;
$SideBarNav_Width_closed: 70px;
$AvatarNav_Height: 120px;
//Nav Menu Styles and themes
//Default Artibulles
$Nav_Menu_FullWidth_Color: rgba(47, 47, 47, 0.98);
$Nav_Menu_Main_Color: rgba(47, 47, 47, 0.98);
$Nav_Menu_Main_hover_Color: rgba(115, 115, 115, 0.98);
// $Nav_Menu_Main_Font: $Nav_Menu_Roboto_Font;
$Nav_Menu_Main_FontSize: 16px;
$Nav_Menu_Main_Font_color: rgb(185, 185, 185);
$Nav_Menu_Main_Font_color_Hover: #ffffff;
//Company
$Company_Font_Size: 30px;
$Company_Font_Color: rgba(255, 255, 255, 0.98);
//SubMenu
$Nav_Menu_Sub_Color: rgba(79, 79, 79, 0.98);
// $Nav_Menu_Sub_Color_hover : $color_Orange;
$Nav_Menu_Sub_SeparationLine_Color: rgba(255, 255, 255, 0.2);
$Nav_Menu_Sub_SeparationLine_width: 0.5px;
// $Nav_Menu_Sub_Font: $Nav_Menu_Roboto_Font;
$Nav_Menu_Sub_FontSize: 14px;
$Nav_Menu_Sub_Font_Color: rgb(185, 185, 185);
$Nav_menu_MainAnimationTime: 600ms;
$Nav_menu_SubShowDropDownAnimTime: 500ms;
$Nav_menu_SubHideDropDownAnimTime: 300ms;
$Nav_menu_SubdeHighlightAnimTime: 300ms;
$Nav_menu_SubHighlightAnimTime: 300ms;
$NavColor: rgba(47, 47, 47, 0.98);
$Artibulles_Orange: #FF6103;
$Artibulles_Pink: #ED2D92;
$Artibulles_Green: #6DBE45;
$Artibulles_Blue: #4997D2;
$Artibulles_Yellow: #FCB415;

//For Each Pages with Main SVG BOX SUMMARY
$Artibulles_SummaryTopPage_Max_Width: 1300px;
$Artibulles_SummaryTopPageSVG_Width: 1200px;
$Artibulles_SummaryTopPageBody_Height: 500px;
$Artibulles_SummaryTopPage_MainText_Padding:0px 10px 0px 250px;
$Artibulles_SummaryTopPage_MainText_flex: 0 0 800px;
$Artibulles_SummaryTopPage_BlockCombination_Width:900px;
$Artibulles_SummaryTopPage_BlockCombination_Height:500px;
$Artibulles_SummaryTopPage_BlockCombination_WrapperMain_Width:490px;
$Artibulles_SummaryTopPage_BlockCombination_WrapperMain_Height:480px;
$Artibulles_SummaryTopPage_BlockCombination_WrapperMain_TopMargin:45px;
$Artibulles_SummaryTopPage_BlockCombination_WrapperMain_LeftMargin:290px;